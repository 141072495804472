<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <h5>{{ $t('menu.publishLottoOffer') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <MultiSelect style="width: 100%" v-model="lotteryModel" :options="lotteries" :placeholder="$t('dropdown.placeholder.lottery')" optionLabel="name" :filter="!this.isMobile()" />
                    </div>
                    <div class="p-col-12 p-md-2 p-text-left"><Button :label="$t('buttons.selectAll')" class="p-button-text p-button-info" @click="selectAll()" /></div>

                    <div class="p-col-12 p-md-2 p-md-offset-2 p-text-right"><Button :label="$t('buttons.importLottoFixtures')" class="p-button-raised p-button-success" @click="publishLottoOffer()" /></div>
                </div>
                <div v-show="lotteryModel.length > 0">
                    <div v-for="lottery in lotteryModel" class="card" :key="'lottery' + lottery.code">
                        <div class="p-grid">
                            <h6>{{ lottery.name }}</h6>
                            <div class="p-col-12 p-grid" v-if="lottery.hasFixedHours == true">
                                <div v-for="(hour, index) in lottery.fixedHours" :key="'hour' + index + 'lottery' + lottery.code" class="p-col-3 p-md-1">
                                    <Checkbox v-model="hour.selected" :binary="true" />
                                    <label class="p-ml-2">{{ hour.hours }}</label>
                                </div>
                            </div>
                            <div v-else class="p-col-12">
                                {{ 'auto hours' }}
                            </div>
                        </div>
                        <br />
                    </div>
                    <div class="p-col-12 p-text-right"><Button :label="$t('buttons.importLottoFixtures')" class="p-button-raised p-button-success" @click="publishLottoOffer()" /></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { LottoApi } from '../../service/LottoApi';
import { BoLottoApi } from '../../service/BoLottoApi';
export default {
    name: 'publishLottoOffer',
    data() {
        return {
            startDateModel: null,
            endDateModel: null,
            lotteries: [],
            lotteryModel: [],
            LottoApi: new LottoApi(),
            BoLottoApi: new BoLottoApi(),
            publishActive: true,
            selectedHours: [],
        };
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';
        var endDateString = year + '-' + month + '-' + day + 'T23:59';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
        this.getLotteriesDesc();
    },
    methods: {
        getLotteriesDesc() {
            this.showErrorMessage = false;
            this.showOkMessage = false;
            this.LottoApi.getLotteriesDesc()
                .then((response) => {
                    var lotteriesArray = [];
                    for (var i = 0; i < response.data.length; i++) {
                        var lottery = {
                            name: response.data[i].name,
                            code: response.data[i].code,
                            _id: response.data[i]._id,
                            hasFixedHours: response.data[i].drawTime.fixedHours != null && response.data[i].drawTime.fixedHours.length > 0 ? true : false,
                            fixedHours: [],
                        };
                        if (lottery.hasFixedHours) {
                            for (var j = 0; j < response.data[i].drawTime.fixedHours.length; j++) {
                                var lotteryHour = {
                                    hours: response.data[i].drawTime.fixedHours[j],
                                    selected: true,
                                };
                                lottery.fixedHours.push(lotteryHour);
                            }
                        }
                        lotteriesArray.push(lottery);
                    }
                    this.lotteries = lotteriesArray;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        selectAll() {
            this.lotteryModel = this.lotteries;
        },
        publishLottoOffer() {
            if (this.publishActive) {
                this.publishActive = false;
                if (this.notNullOrEmptyObject(this.lotteryModel)) {
                    var publishRequest = {
                        StartDateString: this.formatEnDate(this.startDateModel.toString()),
                        EndDateString: this.formatEnDate(this.endDateModel.toString()),
                        Lotteries: [],
                    };
                    for (var i = 0; i < this.lotteryModel.length; i++) {
                        var lotteryPublishedHours = null;
                        if (this.lotteryModel[i].hasFixedHours) {
                            lotteryPublishedHours = this.lotteryModel[i].fixedHours.reduce((arr, obj) => (obj.selected && arr.push(obj.hours), arr), []);
                        }
                        var lotteryObj = {
                            LotteryCode: this.lotteryModel[i].code,
                            PublishHours: lotteryPublishedHours,
                        };
                        publishRequest.Lotteries.push(lotteryObj);
                    }
                    this.BoLottoApi.publishLottoOffer(publishRequest)
                        .then((response) => {
                            this.publishActive = true;
                            if (response.data.status) {
                                this.showToast('success', response.data.message);
                                this.lotteryModel = [];
                            } else {
                                this.showToast('error', response.data.message);
                            }
                        })
                        .catch((error) => {
                            this.publishActive = true;
                            this.checkForUnauthorizedError(error);
                            if (error.response && error.response.status === 404) {
                                if (error.response.data.message) {
                                    this.showToast('error', error.response.data.message);
                                } else {
                                    this.showToast('error', this.$t('alerts.generalError'));
                                }
                            }
                        });
                } else {
                    this.showToast('error', this.$t('lotto.alerts.noLotteryForPublish'));
                    this.publishActive = true;
                }
            }
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
    },
};
</script>